import React from "react"

import Layout from "../components/layout"
import Sustainability from "../components/marketing/sustainability"
import SEO from "../components/seo"

const SustainabilityPage = () => (
  <Layout>
    <SEO title="Sustainability" />
    <Sustainability/>
  </Layout>
)

export default SustainabilityPage
