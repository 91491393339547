import React, { Component } from "react";
import _ from "lodash";
// import { Link, navigate } from 'gatsby'
import Header from "./header"
import SustainabilityBanner from "../../images/sustainability.jpg";

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME

const getMaterials = data => {
  return fetch(`${HOSTNAME}/api/orders/getMaterials`, {
    method: 'GET'
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

class SustainabilityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: {}
    };
  }

  componentDidMount= async(e)=> {
    let value  = await getMaterials()
    this.setState({ materials: value.data })
  }

  formatNumber(number) {
    const nfObject = new Intl.NumberFormat('en-US');
    const output = nfObject.format(number);
    return output
  }

  render() {
    const { materials } = this.state
    return (
      <div>
        <Header/>
        <section className="sustainability__section">
          <img src={SustainabilityBanner} alt="" />
          <div className="abouttext">
            <h1>Our Sustainability Reports</h1>
            <div className="whitebox">
              <h3>Our Sustainability Reports</h3>
              <p>
                See the environmental footprint of our customers
              </p>
              <p>
                We believe companies should be responsible for holding themselves and their customers accountable in terms
                of our impact on our Environment.  That's why we publish near-realtime updates to our sustainability
                numbers to help track our progress, together.
              </p>
              <div className="semibold">recycled in the last 12 months as of TODAYs Date</div>
              <ul>
                <li>
                  {this.formatNumber(_.get(materials, 'metal', ''))} <sub>tons</sub>
                  <h6>Metal</h6>
                </li>
                <li>
                  {this.formatNumber(_.get(materials, 'plastic', ''))} <sub>tons</sub>
                  <h6>Plastic</h6>
                </li>

                <li>
                  {this.formatNumber(_.get(materials, 'paperCardboard', ''))} <sub>tons</sub>
                  <h6>Paper/Cardboard</h6>
                </li>
                {/* <li>
                  33,029 <sub>tons</sub>
                  <h6>Debris</h6>
                </li> */}

                <li>
                  {this.formatNumber(_.get(materials, 'wood', ''))} <sub>tons</sub>
                  <h6>Wood</h6>
                </li>
                <li>
                  {this.formatNumber(_.get(materials, 'concrete', ''))} <sub>tons</sub>
                  <h6>Concrete</h6>
                </li>
                <li>
                  {this.formatNumber(_.get(materials, 'brick', ''))}  <sub>tons</sub>
                  <h6>Brick</h6>
                </li>
                {/* <li>
                  3,029 <sub>tons</sub>
                  <h6>Wood</h6>
                </li> */}
              </ul>
            </div>
          </div>

        </section>
      </div>
    );
  }
}

export default SustainabilityPage;
